import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Amplify from 'aws-amplify';

import Layout from '../layouts/Layout';
import config from '../../aws-exports';
import variables from '../../styles/variables';

Amplify.configure(config);

const HomeScreen = () => (
  <StaticQuery
    query={graphql`
      query Recipes {
        allRecipesJson {
          nodes {
            name
            slug
          }
        }
      }
    `}
    render={({ allRecipesJson: { nodes: recipes } }) => (
      <Layout>
        <Container>
          <h1>Recipes</h1>
          <ul>
            {recipes.map(recipe => (
              <li key={recipe.slug}>
                <Link to={`/recipe/${recipe.slug}`}>{recipe.name}</Link>
              </li>
            ))}
          </ul>
        </Container>
      </Layout>
    )}
  />
);

HomeScreen.propTypes = {};

HomeScreen.defaultProps = {};

const Container = styled.div`
  background-color: #eee;
  border-radius: ${variables.borderRadius * 2}px;
  padding: 40px;
  margin-bottom: 40px;
`;

export default HomeScreen;
