/* eslint-disable react/prop-types */
import React from 'react';
import Amplify from 'aws-amplify';

import HomeScreen from '../components/screens/HomeScreen';
import config from '../aws-exports';

Amplify.configure(config);

const IndexPage = () => {
  return <HomeScreen />;
};

export default IndexPage;
